import utility from '@/common/utility'

const spreadInfo = {
  sheetName: '测试',
  columns: [
    {
      dataKey: 'meterageCode',
      label: '子目号',
      editable: false,
      validations: [
      ],
      width: 100
    },
    {
      dataKey: 'meterageName',
      label: '子目名称',
      editable: false,
      validations: [
      ],
      width: 100
    },
    {
      dataKey: 'unit',
      label: '单位',
      editable: false,
      validations: [
      ],
      width: 90
    },
    {
      dataKey: 'num',
      label: '合同数量',
      editable: false,
      width: 90,
      children: [
        {
          dataKey: 'num',
          label: '原合同数量',
          editable: false,
          dataType: 'number',
          width: 90
        },
        {
          dataKey: 'pictureNum',
          label: '施工图数量',
          dataType: 'number',
          editable: false,
          validations: [
          ],
          width: 90
        }
      ]
    },
    {
      dataKey: 'declarePercent',
      label: '申报百分比',
      editable: true,
      dataType: 'number',
      validator (row) {
        return !(utility.isEmptyString(row.declarePercent) && isNaN(row.declarePercent))
      },
      onChange (row, that) {
        const num = utility.floatDivide(utility.floatMultiple(row.declarePercent, row.pictureNum), 100).toFixed(0)
        that.setValue(row.index, 'declareNum', num)
      },
      width: 90
    },
    {
      dataKey: 'declareNum',
      label: '申报数量',
      editable: false,
      dataType: 'string',
      validator (row) {
        if (row.declareNum !== undefined && row.declareNum !== null && row.declareNum !== '') {
          if (isNaN(row.declareNum)) {
            return false
          }
        }
        return true
      },
      width: 90
    }
  ]
}

export default spreadInfo
