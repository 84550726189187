<template>
  <div class="test-spread">
    <div class="search-bar">
      <el-button type="primary" @click="getData">获取数据</el-button>
    </div>
    <div class="candy-spread">
      <candy-spread
        v-if="defaultDataList.length"
        ref="candySpread"
        :spread-info="spreadInfo"
        :default-data-list="defaultDataList"
        >
      </candy-spread>
    </div>
  </div>
</template>

<script>
import defaultSpreadInfo from '@/views/Test/spreadInfo'
import meterageListService from '@/services/meterageListService'
import CandySpread from '@/components/Spread/CandySpread'

export default {
  name: 'SpreadJs',
  components: {
    CandySpread
  },
  data () {
    return {
      spreadInfo: defaultSpreadInfo,
      defaultDataList: []
    }
  },
  methods: {
    getData () {
      this.$refs.candySpread.validate()
      if (this.$refs.candySpread.errorKeys.length === 0) {
        console.log(this.$refs.candySpread.getData())
      }
    },
    getMeterageList () {
      meterageListService.listByTenderGuid('28B47EB0-4FF9-11EA-881E-1BAB55EEF20E')
        .then(res => {
          if (res.data.code === 1) {
            this.defaultDataList = res.data.data.filter(item => {
              // 去除清单节点数据
              if (item.num === 0) {
                return false
              }
              return true
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  mounted () {
    this.getMeterageList()
  }
}
</script>

<style scoped lang="scss">
.test-spread {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .search-bar {
    line-height: 50px;
  }
  .candy-spread {
    flex: 1;
  }
}
</style>
